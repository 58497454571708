.base-modal .ant-modal-content {
  padding: 24px !important;
}

.fuel-modal .ant-modal-title {
  color: #1d314c;
  font-family: Tomorrow;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.fuel-modal .ant-modal-content {
  width: 476px !important;
  padding: 24px !important;
  background: white !important;
  border: 1px solid rgba(0, 217, 148, 0.4) !important;
  border-radius: 16px !important;
}
