.paradigm-notice-wrapper {
  box-shadow: none !important;
  border-radius: 0 !important;
  color: white;
  background-color: transparent !important;
}
.paradigm-notice-icon {
  display: none !important;
}
.paradigm-notice {
  padding: 1rem !important;
  max-width: 28rem !important;
  border-radius: 10px;
  width: max-content !important;
}
@media screen and (max-width: 768px) {
  .paradigm-notice {
    max-width: calc(100vw - 48px) !important;
  }
}

.paradigm-notice-error {
  background-color: rgba(253, 102, 118);
}
.paradigm-notice-success {
  background-color: #09c2a1;
}
.paradigm-notice-info {
  background-color: rgb(159, 117, 32);
}
.paradigm-notice-message {
  color: white !important;
  margin: 0 !important;
  font-family: 'Tomorrow', sans-serif;
}

.stake-select .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  font-size: 20px;
  font-weight: 500;
}
.stake-select-chain .ant-select-selector {
  @apply pl-0 !important;
  @apply text-base !important;
}
.stake-select-chain .ant-select-selection-item {
  text-align: left !important;
  @apply text-black-title/80 !important;
}
.stake-select .ant-select-selection-item {
  text-align: right;
}
.stake-select .ant-select-arrow {
  @apply text-primary-black;
}
.stake-select-menu,
.chain-select-menu {
  @apply border border-green/40 rounded-lg;
}
.stake-select-menu .ant-select-item {
  font-size: 20px;
  font-weight: 500;
  text-align: right;
}
/* .stake-select-menu .ant-select-item:hover, */
.stake-select-menu .ant-select-item-option-selected {
  @apply bg-green/20 !important;
}

.chain-select-menu .ant-select-item {
  font-size: 16px !important;
  font-weight: 400 !important;
  @apply text-primary-black/80 !important;
}
.chain-select-menu .ant-select-item-option-selected {
  @apply bg-green/20 !important;
}
.history-page .ant-table-container {
  border: none !important;
}

.history-page .ant-table-cell {
  border: none !important;
}

.history-page .ant-table-thead .ant-table-cell {
  background-color: #05d9a60d !important;
}

.history-page .ant-table-tbody-virtual:after {
  border: none !important;
}

.history-mobile-page .ant-table-container {
  border: none !important;
}

.history-mobile-page .ant-table-cell {
  border: none !important;
}

.history-mobile-page .ant-table-thead .ant-table-cell {
  background-color: #05d9a60d !important;
}
