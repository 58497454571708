.banner {
  width: 100%;
  height: 755px;
  background-image: url('./imgs/banner.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-2 {
    width: 100%;
    height: 563px;
    background-image: url('./imgs/bg2.png');
    background-size: cover;
  background-repeat: no-repeat;
}

.bg-3 {
    width: 100%;
    height: 1194px;
    background-image: url('./imgs/card.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: -30px;
}

.bg-4 {
    width: 100%;
    height: 640px;
    background-image: url('./imgs/bg3.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    top: -230px;
    margin-bottom: -230px;
    z-index: -1;
}

.banner-text-bg {
  background: var(
    --Linear,
    linear-gradient(90deg, #35f1cf -18.36%, #ff68e7 109.37%)
  );
  mix-blend-mode: darken;
  height: 88px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

}
