@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Tomorrow:ital,wght@0,300;0,400;0,500;0,600;1,400;1,700&display=swap');

body {
  margin: 0;
  font-family: 'Tomorrow', sans-serif;
  font-size: 16px;
  color: #1d314c;
  line-height: 1.2;
}

* {
  font-family: 'Tomorrow', sans-serif;
}

.font-tomorrow {
  font-family: 'Tomorrow', sans-serif;
}
.font-title {
  font-family: 'Tomorrow', sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  font-style: italic;
}
.font-subtitle {
  font-family: 'Tomorrow', sans-serif;
  font-weight: 600;
  font-size: 32px;
}
.font-content {
  font-family: 'Tomorrow', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.h1-title {
  color: #000;
  font-family: Tomorrow, sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}

.modal-title {
  font-family: 'Tomorrow', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: '#010811';
}
.modal-content {
  font-family: 'Tomorrow', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.btn-primary {
  color: white;
  padding: 16px 24px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  background: #1d314c;
}
.btn-primary:not(:disabled):hover {
  background: #233c5d;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
}
.btn-primary:not(:disabled):active {
  color: rgba(255, 255, 255, 0.8);
  background: #192a41;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
}
.btn-primary:disabled {
  background: rgba(29, 49, 76, 0.5);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}

.btn-reverse {
  color: #233c5d;
  padding: 16px 24px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  background: white;
  border: 1px solid #233c5d;
}
.btn-reverse:not(:disabled):hover {
  background: white;
  opacity: 0.8;
}
.btn-reverse:not(:disabled):active {
  color: rgba(29, 49, 76, 0.8);
  background: white;
}
.btn-reverse:disabled {
  background: white;
  color: rgba(29, 49, 76, 0.5);
  cursor: not-allowed;
  border: 1px solid rgba(29, 49, 76, 0.7);
}

.gradient-text-1 {
  text-align: center;
  background: linear-gradient(89deg, #29ccb9 0%, #0091ff 49%, #ff66b7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.gradient-text-2 {
  text-align: center;
  background: linear-gradient(90deg, #395df2 0%, #2391f6 52.6%, #b072ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.gradient-text-3 {
  text-align: center;
  background: linear-gradient(90deg, #35f1cf -18.36%, #ff68e7 109.37%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.gradient-text-4 {
  text-align: center;
  background: linear-gradient(90deg, #17e978 47.08%, #cb63f9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-5 {
  text-align: center;
  background: linear-gradient(90deg, #56edb6 47.08%, #cb63f9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-bg {
  background: linear-gradient(90deg, #395df2 0%, #2391f6 52.6%, #b072ff 100%);
}

.gradient-bg-1 {
  background: linear-gradient(90deg, #56edb6 -13.69%, #cb63f9 100%);
}

.btn-secondary:disabled {
  cursor: not-allowed;
}

.btn-secondary {
  position: relative;
  padding: 16px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  background: linear-gradient(90deg, #395df2 0%, #2391f6 52.6%, #b072ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn-secondary:before {
  background: linear-gradient(90deg, #395df2 0%, #2391f6 52.6%, #b072ff 100%);
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  border-radius: 8px;
  transition: all 0.5s;
}
.btn-secondary:after {
  background: white;
  content: '';
  display: block;
  position: absolute;
  left: 1px;
  top: 1px;
  bottom: 1px;
  right: 1px;
  z-index: -1;
  border-radius: 7px;
  transition: all 0.5s;
}

.text-manta-gradient {
  background: linear-gradient(90deg, #29ccb9 0%, #0091ff 49%, #ff66b7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text-manta-gradient2 {
  background: linear-gradient(90deg, #395df2 0%, #2391f6 52.6%, #b072ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text-manta-gradient3 {
  background: linear-gradient(90deg, #35f1cf -18.36%, #ff68e7 109.37%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.primary-border {
  border: 1px solid var(--outline, rgba(0, 217, 148, 0.4));
}

.primary-border {
  border: 1px solid var(--outline, rgba(0, 217, 148, 0.4));
}

.bg-primary-gradient {
  background: linear-gradient(90deg, #395df2 0%, #2391f6 52.6%, #b072ff 100%);
}

.bg-button-gradient1 {
  background: linear-gradient(90deg, #35f1cf -18.36%, #ff68e7 109.37%);
}
input[type='checkbox'] {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(40, 88, 253, 0.4);
  background-color: rgba(40, 88, 253, 0.05);
  border-radius: 4px;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
}
input[type='checkbox']:checked {
  border: 0;

  background-image: url('./assets/svgs/checked.svg');
}
/* input[type="checkbox"]:after{
  content:"✔";

} */
.customScroll {
  scrollbar-width: thin;
  scrollbar-color: rgba(29, 49, 76, 0.3) transparent;
}

.customScroll::-webkit-scrollbar {
  width: 8px;
  border: none;
}

.customScroll::-webkit-scrollbar-thumb {
  background-color: rgba(29, 49, 76, 0.3);
  border-radius: 8px;
  border: none;
}

.customScroll::-webkit-scrollbar-track {
  background: transparent;
}

@media (prefers-reduced-motion: no-preference) {
  .loading-spin {
    animation: loading-spin infinite 5s linear;
  }
}

@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.home {
  min-height: calc(100vh - 126px);
}
.home-bg {
  background: url('@/assets/imgs/login-bg.jpg') no-repeat;
  background-size: 100% 100%;
}
.home-bg-h5 {
  background: url('@/assets/imgs/login-bg-h5.jpg') no-repeat;
  background-size: 100% 100%;
}
.dashboard-bg {
  background: url('@/assets/imgs/dashboard-bg.jpg') no-repeat fixed;
  background-size: 100% 100%;
  min-height: calc(100vh - 126px);
}
@media screen and (max-width: 768px) {
  .dashboard-bg {
    background: url('@/assets/imgs/h5-bg-dashboard.png') no-repeat center;
    background-size: cover;
  }
}

@media screen and (max-width: 768px) {
  .h5-ad-banner {
    padding-left: 40%;
    animation: scroll-left 35s linear infinite;
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-350%);
  }
}

input {
  all: unset;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.my-staking-bg {
  height: calc(100vh - 153px);
}

._vi_container {
  width: 469px;
  height: 93px;
}
._vi_character {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 64px;
  font-weight: 300;
  border-radius: 10px;
  border: 2px solid rgba(0, 217, 148, 0.4);
  background: rgba(0, 217, 148, 0.05);
  color: #1d314c;
}
._vi_character--selected {
  border-color: rgba(0, 217, 148, 0.7);
  outline: 1px solid rgba(0, 217, 148, 0.7);
}

._vi_character_error {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 64px;
  font-weight: 300;
  border-radius: 10px;
  border: 2px solid rgba(255, 0, 0, 0.4);
  background: rgba(255, 0, 0, 0.05);
  color: #f00;
}
._vi_character--selected_error {
  border-color: rgba(255, 0, 0, 0.7);
  outline: 1px solid rgba(255, 0, 0, 0.7);
}

@media screen and (max-width: 768px) {
  ._vi_character,
  ._vi_character_error {
    font-size: 36px;
  }
  ._vi_container {
    width: 293px;
    height: 64px;
  }
}

.banner-marquee .rfm-initial-child-container {
  width: 100%;
}
.banner-marquee .rfm-child {
  width: 100%;
}

.ant-table-tbody-virtual-scrollbar-thumb {
  background-color: #0000001a !important;
}

.point-popover{
  border-radius: 4px;
  /* background-color:rgba(29, 49, 76, 0.60) ; */
  box-shadow: none;
}
.point-popover .ant-popover-inner{
  padding: 0;
  background-color:rgba(29, 49, 76, 0.60) ;
 
}

.point-popover .ant-popover-inner-content{
  font-size: 14px;
  color: #ffffff;
  text-align: right;
}
