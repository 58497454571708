.navbar {
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-menu {
  width: 218px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2px);
  transition: 300ms;

  .nav-menu-items {
    width: 100%;

    .navbar-toggle {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
}

.nav-menu-show {
  z-index: 999;
  left: 0;

  @keyframes slidein {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(100%);
    }
  }

  @-webkit-keyframes slidein {
    from {
      -webkit-transform: translateX(0%);
    }
    to {
      -webkit-transform: translateX(100%);
    }
  }
}
