.header-button {
  padding: 15px 16px;
  border-radius: 8px;
  border: 1px solid rgba(0, 217, 148, 0.4);
  background: rgba(0, 217, 148, 0.05);
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.header-button:not(.header-no-hover):hover {
  background: rgba(0, 217, 148, 0.15);
}

@media screen and (max-width: 767px) {
  .header-button {
    display: flex;
    justify-content: space-between;
    text-align: left;
    gap: 0px;
  }

  .header-button-chain{
    width: 135px;
  }
}

.header-dropdown {
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid rgba(0, 217, 148, 0.4);
  background: white;
  font-size: 14px;
  line-height: 20px;
  width: max-content;
}
.dropdown-item {
  width: 100%;
  text-align: left;
  padding: 15px 0;
}
.dropdown-item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 217, 148, 0.4);
}
.dropdown-item:hover {
  color: #05d9a6;
}
.header-link:hover {
  color: #05d9a6;
}
